import React from 'react'
import { images } from 'config/images'

export const ContactProps = {
  contcatAddressProp: {
    heading: (
      <>
        Don’t be shy, <span className="heading-red">say Hi!</span>
      </>
    ),
    addressList: [
      {
        countryName: 'San Francisco',
        address: '388 Market Street, Suite 1300 San Francisco, CA 94111 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Canada',
        address: '15-115 - Bentall II Vancouver BC, V7X 1M8',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'India',
        address:
          '501 Binori B Sq-2, Nr DoubleTree By Hilton, Bopal Rd, Ahmedabad-380054, India',
        tel: 'Call us now',
        telTag: 'tel:+912717452402',
      },
      {
        countryName: 'Los Angeles',
        address: '700 S Flower Street, Suite 1000, Los Angeles, CA 90017 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'San Diego',
        address:
          '2305 Historic Decatur Road, Suite 100, San Diego, CA 92106 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Dallas',
        address: '17250 Dallas Pkwy Dallas,TX 75248 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Austin',
        address: '701 Tillery St #12, Austin, TX 78702 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Chicago',
        address: '73 West Monroe Street, Chicago, IL 60603 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Orlando',
        address: '111 North Orange Avenue Suite 800, Orlando, FL 32801, USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Boston',
        address: '120 St James Ave Floor 6, Boston, MA 02116 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
      {
        countryName: 'Houston',
        address: '2500 Yale St Suite B2, Houston, TX 77008 USA',
        tel: 'Call us now',
        telTag: 'tel:13212372727',
      },
    ],
    followHeading: 'Follow Us',
    SocialMedia: [
      {
        src: images.iconFacebook,
        alt: 'Facebook',
        URL: '/',
      },
      {
        src: images.iconInstagram,
        alt: 'Instagram',
        URL: '/',
      },
      {
        src: images.iconTwitter,
        alt: 'Twitter',
        URL: '/',
      },
      {
        src: images.iconYoutube,
        alt: 'Youtube',
        URL: '/',
      },
    ],
  },
  contactMainSecProps: {
    contentHeading: 'What will happen next?',
    contentPara: (
      <>
        We will get back to you within 24 hours
        <br />
        <br />
        You are a step closer to building great software
      </>
    ),
    contentList: [
      {
        title: '1. Free technical consultation',
        description:
          'Detailed tech plan includes things like what tech stack to use, tech architecture, timeline, and budget for your project.',
      },
      {
        title: '2. Connect with the tech team',
        description:
          'Over a series of calls, our tech team discusses how different technologies and frameworks will bring your vision to life.',
      },
      {
        title: '3. Onboarding the team',
        description:
          'As soon as you sign-off on the team, they’ll be ready to integrate into your team—just like in-house employees.',
      },
    ],
    contactHeding: (
      <>
        Get in touch to discuss your project, request a quote or even just to
        pick our brains.
      </>
    ),
    contactHeadingIcon: images.contactHeadingIcon,
  },
}
